<template>
  <sales
    :current-user-context="currentUserContext"
    :static-start="staticStart"
    :static-end="staticEnd"
    no-data-icon="th-icon-calendar-day"
    widget-key="sales_today"
  />
</template>

<script>
import Sales from './Sales'
import { endOfToday, startOfToday, formatRFC3339 } from 'date-fns'

export default {
  name: 'SalesToday',
  components: {
    Sales
  },
  props: {
    currentUserContext: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  data() {
    return {
      staticStart: formatRFC3339(startOfToday()),
      staticEnd: formatRFC3339(endOfToday())
    }
  }
}
</script>

<style scoped></style>
